import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient,HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { UserService } from '../services/user/user.service';
import { uniqueKey } from 'highcharts';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.css']
})
export class VerifyAccountComponent implements OnInit {
flgVerify = false;
flagExpire = false;
newdate:any;
uniqueKey:any;
 arr1:any=[]
  constructor(
    private route: ActivatedRoute,
    private route11: Router,
    private cookieService: CookieService,
    private http:HttpClient,
    private userService: UserService, 
  ) { }

  ngOnInit(): void {

    
   let url:any=this.route11.url;
   console.log(url);
  
   this.route.snapshot.url.forEach(segment => {    
     // Logs each segment of the URL 
    
        this.arr1.push(segment.path)

     });
 
console.log(this.arr1)
   
  
    this.newdate=this.arr1[3];
    this.uniqueKey=this.arr1[2];
   
    debugger
    this.checkIsVerify();
  }
  checkIsVerify(){
  debugger

    var date:any = new Date(parseInt(this.newdate));
    var currentdate:any = new Date();
    var day :any= (currentdate - date) / (1000 * 60 * 60 * 24);
  
    day = Math.round(day);
    let params = new HttpParams();
   
    params = params.append('confirmpassword', day);
    params = params.append('UniqueNumber',this.uniqueKey);
    if (day < 1) {
      this.http.get(environment.serviceUrl+ 'account/checkIsVerify?UniqueNumber?'+params).subscribe((res:any)=>{
        console.log(res);    
        if (res.success == true) {
                this.flgVerify = true;
            }
        });
    } else {
        this.flagExpire = true;
    }

}
  
}
