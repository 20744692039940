export const environment = {
  production: true,
  // serviceUrl:"http://52.36.33.125:3737/",
 serviceUrl:"https://api.matoflife.com/",
  // serviceUrl:"http://localhost:3737/",
  firebase: {
    messagingSenderId: "410789206504",
    apiKey: "AIzaSyBVY4SG7IpQrMZJcHfupLhsan9siU9uzPk",
    projectId: "matoflife-e7875",
    appId: "1:410789206504:web:753bb8dc4649256b2bac5d"
  }
};
