import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerDevicesComponent } from '../../customer-devices/customer-devices.component';
export interface DialogData {
   [x: string]: any;
  // customerId: any;
   id: any;
   device:any
 
  // responseType: any;
  // remarks: any;
}

@Component({
  selector: 'app-device-type',
  templateUrl: './device-type.component.html',
  styleUrls: ['./device-type.component.css']
})
export class DeviceTypeComponent implements OnInit {

  p= 'abc'
  id: any;
  DeviceType: any;
  devices: any;
  constructor(public dialogRef: MatDialogRef<DeviceTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private stepReportService: CustomerDevicesComponent) { }

  ngOnInit(): void {
    this.id=this.data.id
    this.devices=this.data.device;
    this.DeviceType= this.data.deviceType;
    console.log(this.DeviceType);
  }


   ConvertToTime1(Scheduler_StartTime) {

    if (Scheduler_StartTime != null) {
        var splitedData = Scheduler_StartTime.split(':')
        if (parseInt(splitedData[0]) < 12) {
            if (splitedData[0] == 0) {
                return 12 + ":" + splitedData[1] + " AM";
            } else {
                return splitedData[0] + ":" + splitedData[1] + " AM";
            }
        } else {
            if (parseInt(splitedData[0]) - 12 == 0) {
                return 12 + ":" + splitedData[1] + " PM";
            } else {
                return parseInt(splitedData[0]) - 12 + ":" + splitedData[1] + " PM";
            }
        }
    } else {
        return null;  
    }
}
ConvertToTime2(Scheduler_EndTime) {

  if (Scheduler_EndTime != null) {
      var splitedData = Scheduler_EndTime.split(':')
      if (parseInt(splitedData[0]) < 12) {
          if (splitedData[0] == 0) {
              return 12 + ":" + splitedData[1] + " AM";
          } else {
              return splitedData[0] + ":" + splitedData[1] + " AM";
          }
      } else {
          if (parseInt(splitedData[0]) - 12 == 0) {
              return 12 + ":" + splitedData[1] + " PM";
          } else {
              return parseInt(splitedData[0]) - 12 + ":" + splitedData[1] + " PM";
          }
      }
  } else {
      return null;
  }
}






}
