import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login/login.service'
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MessagingService } from '../services/messaging/messaging.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup
  userDetails: any;
  loginInvalid: any = false;
  deviceToken: any;
  hide: any = true;
  lodaerHide = false;

  constructor(private router: Router, private messagingService: MessagingService, private angularFireMessaging: AngularFireMessaging, private formBuilder: FormBuilder, private cookieService: CookieService, private loginService: LoginService) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    }, {
      // validator: MustMatch('password', 'confirmPassword')
    });

    this.requestPermission();

  }
  requestPermission() {

    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.deviceToken = token;
        // alert(this.deviceToken)
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  openOldURL(url: string) {
    window.location.href = url;
  }

  async onSubmit() {
    this.lodaerHide = true
    if (this.loginForm.invalid) {
      this.lodaerHide = false

      return;

    }
    console.log(this.deviceToken);
    // console.log(this.loginForm.value);
    this.loginForm.value.deviceToken = this.deviceToken;
    await this.loginService.login(this.loginForm.value).toPromise().then((res) => {
      this.userDetails = res;
      console.log(res);
      if (this.userDetails.success == true) {
        this.lodaerHide = false;
        this.cookieService.set('UserImage', this.userDetails.UserImage);
        this.cookieService.set('UserName', this.userDetails.UserName);
        this.cookieService.set('UserRoles', this.userDetails.UserRoles[0]);
        this.cookieService.set('UserId', this.userDetails.UserId);
        this.cookieService.set('FirstName', this.userDetails.FirstName);
        this.cookieService.set('LastName', this.userDetails.LastName);
        this.cookieService.set('EmailAddress', this.userDetails.UserName);
        this.cookieService.set('OrganizationName', this.userDetails.OrganizationName);
        this.cookieService.set('AccountType', this.userDetails.AccountType);
        this.cookieService.set('organizationId', this.userDetails.organizationId);
        //$rootScope.AccountType = $cookieStore.get('AccountType');
        this.cookieService.set('token', this.userDetails.token);

      } else {
        this.loginInvalid = true;
      }
      this.lodaerHide = false

    })

    if (this.userDetails.success == true) {
      this.router.navigate(['/steps-report'])
    }

    //this.submitted = true;
    //this.router.navigate(['/organization']);
  }

}
