import { Component, OnInit, Injectable } from '@angular/core';
import { OrganizationService } from '../services/organization/organization.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
// import { Console } from 'console';
import { DeviceListService } from '../services/deviceList/device-list.service'
import { CustomerService } from '../services/customer/customer.service'
import { StepReportService } from '../services/step-report/step-report.service'
import { DeviceTypeComponent } from "../modals/device-type/device-type.component";
import { DeleteDeviceComponent } from "../modals/delete-device/delete-device.component";
import { MatDialog } from '@angular/material/dialog';
import { MessagingService } from '../services/messaging/messaging.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { AlarmSettingsComponent } from 'app/modals/alarm-settings/alarm-settings.component';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-customer-devices',
  templateUrl: './customer-devices.component.html',
  styleUrls: ['./customer-devices.component.css']
})

export class CustomerDevicesComponent implements OnInit {
  tablehide:boolean=true;
  eventList: any;
  record: any;
  showMat:any=false;
  showSocket:any=false;
  showAlarm:any=false;
  country: any;
  device: any;
  DeviceType: any;
  smsList: any
  result: any
  id:  any;
  matResult:any
  disable:any=false;
  matList:any;
  customerDevice: any
  stepsList: any;
  totalRecord: any;
  page: any = 1;
  page1: any = 1;
  pageLength: any = "10";
  events: any = 'Client didnot returned';
  TimingList: any
  TimingListforMDtime: any
  selectedItem: any;
  UserId:any; 
  show; any;
  Scheduling: any = false;
  smsAdd: any;
  dialCode:any="+91";
  showSms: any = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  currentMessage = new BehaviorSubject(null);
  showCard:any=false;
  // tableListHide:any=true;
  orgName:any;
  organizationDetails:any;
  orgId:any;
  Add:any=false;
  SNo = 1;
  selected = '10';

  public Name: any = "";
  totalData: [];
  constructor(
    private stepReportService: StepReportService,
    private deviceListService: DeviceListService,
    private customerService: CustomerService,
    public dialog: MatDialog,
    private messagingService: MessagingService,
    private angularFireMessaging: AngularFireMessaging,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private cookieService:CookieService,
    private organizationService:OrganizationService
  ) {
    this.eventList = new Array<any>()
    this.events = ""
  }

  ngOnInit(): void {
    this.pageLength = "10"
    this.totalRecord = "2"
    this.messagingService.requestPermission();
    this.orgId    = this.cookieService.get('organizationId');
    this.getOrganizationDetails(this.orgId)
    this.UserId = this.cookieService.get('UserId');
    this.getMatList()
    //this.getDeviceType()
    this.TimingListforMDtime = [{ value: 10000, time: '10 Seconds' }, { value: 15000, time: '15 Seconds' }, { value: 20000, time: '20 Seconds' }, { value: 25000, time: '25 Seconds' }, { value: 30000, time: '30 Seconds' }, { value: 60000, time: '1 Minute' }, { value: 120000, time: '2 Minute' }, { value: 180000, time: '3 Minute' }, { value: 240000, time: '4 Minute' }, { value: 300000, time: '5 Minute' }, { value: 360000, time: '6 Minute' }, { value: 420000, time: '7 Minute' }, { value: 480000, time: '8 Minute' }, { value: 540000, time: '9 Minute' }, { value: 600000, time: '10 Minute' }, { value: 900000, time: '15 Minute' }, { value: 1200000, time: '20 Minute' }, { value: 1500000, time: '25 Minute' }, { value: 1800000, time: '30 Minute' }];
    this.TimingList = [{ value: 30000, time: '30 Seconds' }, { value: 60000, time: '1 Minute' }, { value: 120000, time: '2 Minute' }, { value: 180000, time: '3 Minute' }, { value: 240000, time: '4 Minute' }, { value: 300000, time: '5 Minute' }, { value: 360000, time: '6 Minute' }, { value: 420000, time: '7 Minute' }, { value: 480000, time: '8 Minute' }, { value: 540000, time: '9 Minute' }, { value: 600000, time: '10 Minute' }, { value: 660000, time: '11 Minute' }, { value: 720000, time: '12 Minute' }, { value: 780000, time: '13 Minute' }, { value: 840000, time: '14 Minute' }, { value: 900000, time: '15 Minute' }, { value: 960000, time: '16 Minute' }, { value: 1020000, time: '17 Minute' }, { value: 1080000, time: '18 Minute' }, { value: 1140000, time: '19 Minute' }, { value: 1200000, time: '20 Minute' }, { value: 1500000, time: '25 Minute' }, { value: 1800000, time: '30 Minute' }, { value: 2100000, time: '35 Minute' }, { value: 2400000, time: '40 Minute' }, { value: 2700000, time: '45 Minute' }, { value: 3000000, time: '50 Minute' }, { value: 3300000, time: '55 Minute' }, { value: 3600000, time: '1 Hour' }];
    this.show = this.messagingService.currentMessage
    this.getUserList(this.page,this.pageLength)
    this.eventList = [
      // "Mat",
      // "Alarm",
      // "Socket"
    ]
    this.customerDevice = this.formBuilder.group({
      DeviceName: ['', Validators.required],
      DeviceSerial: ['', Validators.required],
      MD_Timer: ['', Validators.required],
      LED_Timer: ['', Validators.required],
      Standby_Timer: ['', Validators.required],
      Alert_Msg_Timer: ['', Validators.required],
      Text_for_1St_Step: ['', Validators.required],
      Text_for_2nd_Step: ['', Validators.required],
      Text_for_Alert_Event: ['', Validators.required],
      Type: ['', ''],
      Device_Mat_Id: ['', ''],
      Email: ['', ''],
      Event_Scheduler_EndTime: ['', ''],
      Event_Scheduler_StartTime: ['', ''],
      IsActive: ['', ''],
      MobileNo: ['', ''],
      MobileNo_AlertSMS: ['', ''],
      MobileNo_FirstStep: ['', ''],
      MobileNo_SecondStep: ['', ''],
      ModifiedBy: ['', ''],
      ModifiedDate: ['', ''],
      SchedulType: ['Always On', ''],
      SchedulType_AlertSMS: ['Always On', ''],
      SchedulType_FirstStep: ['Always On', ''],
      SchedulType_SecondStep: ['Always On', ''],
      Scheduler_EndTime: ['', ''],
      Scheduler_EndTime_AlertSMS: ['', ''],
      Scheduler_EndTime_FirstStep: ['', ''],
      Scheduler_EndTime_SecondStep: ['', ''],
      Scheduler_StartTime: ['', ''],
      Scheduler_StartTime_AlertSMS: ['', ''],
      Scheduler_StartTime_FirstStep: ['', ''],
      Scheduler_StartTime_SecondStep: ['', ''],
      Setting_Sync_Flag: ['', ''],
      TriggerType: ['', ''],
      id: ['', ''],
      Alert_Scheduler_Endtime: ['', ''],
      Alert_Scheduler_StartTime: ['', ''],
      Connection_Status: ['', ''],
      CreatedBy: ['', ''],
      CreatedDate: ['', ''],
      CustomerDeviceId: ['', ''],
      CustomerMasterId: ['', ''],
      DeviceType: [{value:"",disable:true}, ""],
      LightOff_Timer: ["", ""],
      Alarm_Time:["",""]
    }, {});
    this.smsAdd = this.formBuilder.group({
      id: ['', ''],
      Alert: ['', ""],
      Device_Faliure: ['', ''],
      First_Step: ['', ""],
      MobileNo: ['',[
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      ]],
      Scheduler_EndTime: ['', ""],
      Scheduler_StartTime: ['', ""],
      Second_Step: ['', ''],
      Text_for_1St_Step: ['', ''],
      Text_for_2nd_Step: ['', ''],
      Text_for_Alert_Event: ['', ''],
      Device_Mat_Id: ['', '']
    }, {});
  }
  onSmsAdd() {
if(this.smsAdd.value.MobileNo){
    this.smsAdd.value.MobileNo=this.dialCode+this.smsAdd.value.MobileNo
    if (!this.smsAdd.value.Scheduler_StartTime) {
      this.smsAdd.value.Scheduler_StartTime = "00:00:00"
      this.smsAdd.value.Scheduler_EndTime = "23:59:59"
    } else {
      this.smsAdd.value.Scheduler_StartTime = this.smsAdd.value.Scheduler_StartTime + ":00"
      this.smsAdd.value.Scheduler_EndTime = this.smsAdd.value.Scheduler_EndTime + ":00"
    }
    this.smsList.push(this.smsAdd.value)
    if (this.smsList.length > 0) {
      this.showSms = true
    }
    console.log(this.smsAdd.value)}

  }
  public errorHandling1 = (control: string, error: string) => {
    return this.smsAdd.controls[control].hasError(error);
  };
  public errorHandling = (control: string, error: string) => {
    return this.customerDevice.controls[control].hasError(error);
  };
  toRemove(index,data){
    console.log(data.id)
    this.smsList.splice(index, 1)
     this.customerService.removeSmsDetails(data.id).subscribe((res) => {
      console.log(res)

    })
  }
  private getOrganizationDetails(id){
    if(id==0){
      this.orgName="-"
    }else{
      this.organizationService.getOrganizationById(id).subscribe((res)=>{
      this.organizationDetails=res;
      this.orgName = this.organizationDetails.name;
      console.log(this.organizationDetails);
      });
   }
  }
  onCountryChange(event){
    this.dialCode="+"+event.dialCode
    // console.log(event.dialCode);
    // console.log(event.name);
    // console.log(event.iso2);
  }
  AddDeviceByType(type) {
    console.log(this.customerDevice.get('DeviceType').value)
    if(type === "Mat" || this.DeviceType === "Mat"){
      this.showMat=true
      this.showAlarm=false
      this.showSocket=false
    }else if(type === "Alarm" || this.DeviceType === "Alarm"){
      this.showAlarm=true
      this.showMat=false
      this.showSocket=false
    }else if (type === "Socket" || this.DeviceType === "Socket"){
      this.showSocket= true
      this.showAlarm=false
      this.showMat=false
    }
  }
  SaveCustomerDevice() {
  
    if (!this.customerDevice.value.Scheduler_StartTime) {
      this.customerDevice.value.Scheduler_StartTime = "00:00:00"
      this.customerDevice.value.Scheduler_EndTime = "23:59:59"
    } else {
      this.customerDevice.value.Scheduler_StartTime = this.customerDevice.value.Scheduler_StartTime + ":00"
      this.customerDevice.value.Scheduler_EndTime = this.customerDevice.value.Scheduler_EndTime + ":00"
    }
    var data = this.customerDevice.value
    data.SMSList = this.smsList;
    console.log(this.customerDevice.value)
    console.log(this.smsList) 
    if (this.customerDevice.invalid){
      // console.log('form valuesafhavakvk',save_patient)
      // this.toastr.error('Please fill required fields');
    
      return;
    }
    console.log(data)
    this.customerService.saveCustomerDevice(data).subscribe((res) => {
      if(res){
        this.customerDevice.reset()
        this.smsAdd.reset()
        this.getUserList(this.page,this.pageLength)
        this.showCard=false
      }
    })
  }

  CloseCard(){
    // this.tableListHide=true;
    let getId=document.getElementById("getId");
    console.log(getId.id)
    getId.classList.add('thead-fixed4')
    this.customerDevice.reset()
    this.smsAdd.reset()
    this.getUserList(this.page,this.pageLength)
    this.showCard=false;
    this.tablehide=true;
  }

  showScheduling() {
    this.Scheduling = true
  }
  showNothing() {
    this.Scheduling = false
  }

  onAddDevice(){
    let getId=document.getElementById("getId");
    console.log(getId.id)
    getId.classList.remove('thead-fixed4')
    this.showCard=true;

    // this.tableListHide=false;
    this.Add=true;
    this.disable=false;
    this.customerDevice = this.formBuilder.group({
      DeviceName: ['', Validators.required],
      DeviceSerial: ['', Validators.required],
      MD_Timer: ['', Validators.required],
      LED_Timer: ['', Validators.required],
      Standby_Timer: ['', Validators.required],
      Alert_Msg_Timer: ['', Validators.required],
      Text_for_1St_Step: ['', Validators.required],
      Text_for_2nd_Step: ['', Validators.required],
      Text_for_Alert_Event: ['', Validators.required],
      Type: ['', ''],
      Device_Mat_Id: ['', ''],
      Email: ['', ''],
      Event_Scheduler_EndTime: ['', ''],
      Event_Scheduler_StartTime: ['', ''],
      IsActive: ['', ''],
      MobileNo: ['', ''],
      MobileNo_AlertSMS: ['', ''],
      MobileNo_FirstStep: ['', ''],
      MobileNo_SecondStep: ['', ''],
      ModifiedBy: ['', ''],
      ModifiedDate: ['', ''],
      SchedulType: ['Always On', ''],
      SchedulType_AlertSMS: ['Always On', ''],
      SchedulType_FirstStep: ['Always On', ''],
      SchedulType_SecondStep: ['Always On', ''],
      Scheduler_EndTime: ['', ''],
      Scheduler_EndTime_AlertSMS: ['', ''],
      Scheduler_EndTime_FirstStep: ['', ''],
      Scheduler_EndTime_SecondStep: ['', ''],
      Scheduler_StartTime: ['', ''],
      Scheduler_StartTime_AlertSMS: ['', ''],
      Scheduler_StartTime_FirstStep: ['', ''],
      Scheduler_StartTime_SecondStep: ['', ''],
      Setting_Sync_Flag: ['', ''],
      TriggerType: ['', ''],
      id: ['', ''],
      Alert_Scheduler_Endtime: ['', ''],
      Alert_Scheduler_StartTime: ['', ''],
      Connection_Status: ['', ''],
      CreatedBy: ['', ''],
      CreatedDate: ['', ''],
      CustomerDeviceId: ['', ''],
      CustomerMasterId: ['', ''],
      DeviceType: [{value:"",disable:true}, ""],
      LightOff_Timer: ["", ""],
      Alarm_Time:["",""]
    }, {});
  }

  onEdit(DeviceType, id) {
    this.showSms = false;
    this.tablehide=false;
    this.disable=true;
    this.selectedItem = DeviceType
    this.Add=false;
    this.customerDevice = this.formBuilder.group({
      DeviceName: ['', Validators.required],
      DeviceSerial: ['', Validators.required],
      MD_Timer: ['', Validators.required],
      LED_Timer: ['', Validators.required],
      Standby_Timer: ['', Validators.required],
      Alert_Msg_Timer: ['', Validators.required],
      Text_for_1St_Step: ['', Validators.required],
      Text_for_2nd_Step: ['', Validators.required],
      Text_for_Alert_Event: ['', Validators.required],
      Type: ['', ''],
      Device_Mat_Id: ['', ''],
      Email: ['', ''],
      Event_Scheduler_EndTime: ['', ''],
      Event_Scheduler_StartTime: ['', ''],
      IsActive: ['', ''],
      MobileNo: ['', ''],
      MobileNo_AlertSMS: ['', ''],
      MobileNo_FirstStep: ['', ''],
      MobileNo_SecondStep: ['', ''],
      ModifiedBy: ['', ''],
      ModifiedDate: ['', ''],
      SchedulType: ['Always On', ''],
      SchedulType_AlertSMS: ['Always On', ''],
      SchedulType_FirstStep: ['Always On', ''],
      SchedulType_SecondStep: ['Always On', ''],
      Scheduler_EndTime: ['', ''],
      Scheduler_EndTime_AlertSMS: ['', ''],
      Scheduler_EndTime_FirstStep: ['', ''],
      Scheduler_EndTime_SecondStep: ['', ''],
      Scheduler_StartTime: ['', ''],
      Scheduler_StartTime_AlertSMS: ['', ''],
      Scheduler_StartTime_FirstStep: ['', ''],
      Scheduler_StartTime_SecondStep: ['', ''],
      Setting_Sync_Flag: ['', ''],
      TriggerType: ['', ''],
      id: ['', ''],
      Alert_Scheduler_Endtime: ['', ''],
      Alert_Scheduler_StartTime: ['', ''],
      Connection_Status: ['', ''],
      CreatedBy: ['', ''],
      CreatedDate: ['', ''],
      CustomerDeviceId: ['', ''],
      CustomerMasterId: ['', ''],
      DeviceType: [{value:"",disable:true}, ""],
      LightOff_Timer: ["", ""],
      Alarm_Time:["",""]
    }, {});
    console.log(this.selectedItem)

    this.customerDevice.get('DeviceName').setValue(this.selectedItem.DeviceName);
    this.customerDevice.get('DeviceSerial').setValue(this.selectedItem.DeviceSerial);
    this.customerDevice.get('MD_Timer').setValue(this.selectedItem.MD_Timer);
    this.customerDevice.get('LED_Timer').setValue(this.selectedItem.LED_Timer);
    this.customerDevice.get('Standby_Timer').setValue(this.selectedItem.Standby_Timer);
    this.customerDevice.get('Alert_Msg_Timer').setValue(this.selectedItem.Alert_Msg_Timer);
    this.customerDevice.get('Text_for_1St_Step').setValue(this.selectedItem.Text_for_1St_Step);
    this.customerDevice.get('Text_for_2nd_Step').setValue(this.selectedItem.Text_for_2nd_Step);
    this.customerDevice.get('Text_for_Alert_Event').setValue(this.selectedItem.Text_for_Alert_Event);
    this.customerDevice.get('id').setValue(this.selectedItem.id);
    this.customerDevice.get('Type').setValue(this.selectedItem.Type);
    this.customerDevice.get('Connection_Status').setValue(this.selectedItem.Connection_Status);
    this.customerDevice.get('CreatedDate').setValue(this.selectedItem.CreatedDate);
    this.customerDevice.get('CustomerMasterId').setValue(this.selectedItem.CustomerMasterId);
    this.customerDevice.get('IsActive').setValue(this.selectedItem.IsActive);
    this.customerDevice.get('Setting_Sync_Flag').setValue(this.selectedItem.Setting_Sync_Flag);
    this.customerDevice.get('DeviceType').setValue(this.selectedItem.DeviceType);
    this.customerDevice.get('CreatedBy').setValue(this.selectedItem.tblcustomermaster.EmailAddress);
    this.customerDevice.get('Alarm_Time').setValue(this.selectedItem.Alarm_Time);
    this.customerDevice.get('TriggerType').setValue(this.selectedItem.TriggerType);
    this.customerDevice.get('LightOff_Timer').setValue(this.selectedItem.LightOff_Timer);
    this.smsAdd.get('Device_Mat_Id').setValue(this.selectedItem.Device_Mat_Id);
    this.showCard=true 
    this.getMatList()
    if( this.selectedItem.DeviceType === "Mat"){
      this.showMat=true
      this.showAlarm=false
      this.showSocket=false
    }else if(this.selectedItem.DeviceType === "Alarm"){
      this.showAlarm=true
      this.showMat=false
      this.showSocket=false
    }else if (this.selectedItem.DeviceType === "Socket"){
      this.showSocket= true
      this.showAlarm=false
      this.showMat=false
    }

    this.customerService.GetAllSMSByMatId(id).subscribe((res) => {
      console.log(res)
      this.result = res;
      this.smsList = this.result.data;
      if (this.smsList.length === 0) {
        this.showSms = false
      } else {
        this.showSms = true
      }

    })
  }

  getUserList(page,length) {

    this.page=page,
    this.pageLength=length
    console.log("entered")
    this.customerService.getAllUser().subscribe((res) => {
     console.log("amama---->",res)
      this.eventList = res;
      this.DeviceType = this.eventList.DeviceType;
      this.id = this.eventList.id;
      this.totalRecord = this.eventList.length;
    })
  }
   
  getAllList(page1, limit, event) {
    // alert(page1)
    console.log(event);
    // var p = page1-1;
    // if(p > 0){
    //   this.SNo = p*10;
    //   this.SNo = this.SNo + 1;
    // }else{
    //   this.SNo = 1;
    // }
    // this.page = page1;
    // var start = (page1 - 1) * 10;
    // console.log(start)
  }
getMatList(){
   this.customerService.GetMatList(this.UserId).subscribe((res) => {
      console.log(res)
       this.matResult = res;
       this.matList=this.matResult.data
     })
}

  getDeviceType(DeviceType, id) {
    
    this.customerService.getDeviceType(DeviceType, id).subscribe((res) => {

      this.device = res;
      // console.log(this.device)  
      this.openDeviceType(DeviceType, id)
    })
  }
  Search() {
    console.log("serch hit")
    console.log(this.Name)
    if (this.Name == "") {
      console.log("oninit")

    } else {
      console.log("serch")
      this.totalData = this.stepsList.data.rows.filter(res => {
        console.log(res.tblcustomermaster.Name.toLowerCase())
        console.log(this.Name.toLowerCase())
        return res.tblcustomermaster.Name.toLowerCase().match(this.Name.toLowerCase())
      })
      // console.log(this.totalData)
    }
  }


  openDeviceType(DeviceType, id): void {

    console.log(DeviceType)
    const dialogRef = this.dialog.open(DeviceTypeComponent, {
      width: '800px',
      data: { id: id, device: this.device, deviceType: DeviceType }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.animal = result;
      // window.location.reload();
      // this.getAllList(this.page, this.Name, this.pageLength, this.events);
    });
  }

  DeleteDevice(DeviceType, id): void {

    console.log(this.device)
    const dialogRef = this.dialog.open(DeleteDeviceComponent, {
      width: '600px',
      data: { id: id, device: DeviceType }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.getUserList(this.page,this.pageLength)
      // this.animal = result;
      // window.location.reload();
      // this.getAllList(this.page, this.Name, this.pageLength, this.events);
    });
  }




}

