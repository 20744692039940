import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Organization} from './organization.model';
import { CookieService } from 'ngx-cookie-service';



@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  

  constructor(public httpClient: HttpClient, private cookieService:CookieService) { 
    this.getAllCountry();
    
  }
  public getAllCountry(){
    return this.httpClient.get(environment.serviceUrl+'customer/GetAllCountry');
  }
  public createOrganization(rawData){
    return this.httpClient.post(environment.serviceUrl+'organization/Save_organization',rawData);
  }
  public getOrganizationById(id:any){
    return this.httpClient.get(environment.serviceUrl+'organization/getOrganizationById?id='+id);
  }
  public getAllOrganization(){
   // return this.httpClient.get(environment.serviceUrl+'organization/organizationList');
    return this.httpClient.get(environment.serviceUrl+'organization/organizationList');
  }
  public getAllOrganization1(){
    // return this.httpClient.get(environment.serviceUrl+'organization/organizationList');
     return this.httpClient.get(environment.serviceUrl+'organization/organizationList');
   }
   public getAllOrderby(start,Name,Limit,event){
    console.log("entered"+ start +" "+ Name +" "+ Limit)
    return this.httpClient.post(environment.serviceUrl+'organization/organizationList', {offset:start,limit:parseInt(Limit),search:Name,event:event});
  }
  public updateOrganization(id:any,rawData: Organization){
    //console.log(environment.serviceUrl+"organization/updateOrganization/"+id);
    return this.httpClient.post(environment.serviceUrl+"organization/updateOrganization", rawData);
  }

  public GetAllCustomerDeviceByUser(){
    return this.httpClient.get(environment.serviceUrl+'customerdevices/GetAllCustomerDeviceByUser?EmailAddress='+this.cookieService.get("UserName"));
  }
  public getOrganizationDataByid(rawData){
    return this.httpClient.post(environment.serviceUrl+'organization/getCustomerByOrganization',rawData);
  }
}