import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LinkedDevicesService } from '../../services/linkedDevices/linked-devices.service';


export interface DialogData {
  cordlessMat: any;
}

@Component({
  selector: 'app-cordless-devices',
  templateUrl: './cordless-devices.component.html',
  styleUrls: ['./cordless-devices.component.css']
})
export class CordlessDevicesComponent implements OnInit {
  cordlessMat: any;
  cordlessMat_details: any;
  constructor(public dialogRef: MatDialogRef<CordlessDevicesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public linkedDevicesService: LinkedDevicesService) { }

  ngOnInit(): void {

    // console.log(this.data.cordlessMat);
    this.cordlessMat = this.data.cordlessMat;
    this.GetAllCordlessDevice(this.cordlessMat.id);
  }

  GetAllCordlessDevice(MatId) {
    this.linkedDevicesService.GetAllCordlessDevice(MatId).then(response => {
      console.log(response);
      if (response.success) {
        this.cordlessMat_details = response.data;
      }else {

      }
    }).catch(err => console.log(err));
  }

}
