import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirmwareService } from "../../services/firmware/firmware.service";
//import Swal from '../../../../node_modules/sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2/dist/sweetalert2.js';



export interface DialogData {
  deviceType: string;
  deviceList:any;
}


@Component({
  selector: 'app-firmware-modal',
  templateUrl: './firmware-modal.component.html',
  styleUrls: ['./firmware-modal.component.css']
})
export class FirmwareModalComponent implements OnInit {
  deviceListTypeWiseFile:any;
  fileName:any;
  result:any;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<FirmwareModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public firmwareService:FirmwareService) {
     // Swal.fire('Hello world!');
     }

  ngOnInit(): void {
    // console.log(this.data);
    
    this.getDeviceTypeFileList(this.data.deviceType);
  //  Swal.fire('Oops...', 'Something went wrong!', 'error');

  }

  getDeviceTypeFileList(deviceType){
    let params = {
      "DeviceType":deviceType
    }
    this.firmwareService.getFileListAsPerDeviceType(params).subscribe((res)=>{
      this.deviceListTypeWiseFile = res;
    })
  }
  handleChange(event){
    this.fileName = event.value;
    console.log(event);
  }

  updateFirmware(){
    Swal.fire({
      title: '',
      text: 'Are You sure want to upgrade ? Improper steps might loss Device permanently.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, you want upgrade it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        var params = {
          DeviceList:this.data.deviceList,
          DeviceType:this.data.deviceType,
          Filename:this.fileName
        };
        this.firmwareService.updateOrganization(params).subscribe((res)=>{
          this.result = res;
          console.log(res)
          Swal.fire(
            "success",
            this.result.message,
            this.result.success
          );
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Okay',
          'error'
        )
      }
    });
  }
}
