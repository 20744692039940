import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { CustomerService } from 'app/services/customer/customer.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
export interface DialogData{
  // customerId: any;
   data:any
  // responseType: any;
  // remarks: any;
}
@Component({
  selector: 'app-customer-service-debug',
  templateUrl: './customer-service-debug.component.html',
  styleUrls: ['./customer-service-debug.component.css']
})
export class CustomerServiceDebugComponent implements OnInit {
  temp:any;
  messageshow:any;
  modelHide=true;
  // constructor(public http:HttpClient) { }
  constructor(public dialogRef: MatDialogRef<CustomerServiceDebugComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private stepReportService: CustomerService,private http:HttpClient) { }

  ngOnInit(): void {
    
     this.temp=this.data
     console.log(this.temp)
  }
  deviceReboot(url){
    console.log(url.value)
     const params=new HttpParams()
     .set('CustomerMasterId',this.temp.CustomerMasterId)
     .set('DeviceName',this.temp.DeviceName)
     .set('DeviceSerial',this.temp.DeviceSerial)
     .set('DeviceType',this.temp.DeviceType)
     .set('id',this.temp.id)
  var path=environment.serviceUrl+'customerdevices/'+url.value+'?=';
  console.log(params)
  console.log(path)
   this.http.get(path+params).subscribe((res)=>{
      console.log(res);
      this.messageshow=res
      console.log(this.messageshow.message)
      Swal.fire(
        this.messageshow.message
      )
   
     
        this.dialogRef.close();
      
    })
   

  //    if(url.value=='r_b'){
  //     this.http.get(environment.serviceUrl+'customerdevices/RebootDevice'+params).subscribe((res)=>{
  //       console.log(res);
  //   })
  // }
  //   if(url.value=='turn_on'){
  //     this.http.get(environment.serviceUrl+'customerdevices/TurnOnMatLED'+params).subscribe((res)=>{
  //       console.log(res);
  //   })
  // }
  //   if(url.value=='config_mode'){
  //     this.http.get(environment.serviceUrl+'customerdevices/DeviceInConfigMode'+params).subscribe((res)=>{
  //       console.log(res);
  //   })
  // }
  //   if(url.value=='turn_off'){
  //     this.http.get(environment.serviceUrl+'customerdevices/TurnOffMatLED'+params).subscribe((res)=>{
  //       console.log(res);
  //   })
  // }
  //   if(url.value=='d_identify'){
  //     this.http.get(environment.serviceUrl+'customerdevices/DeviceIdentify'+params).subscribe((res)=>{
  //       console.log(res);
  //   })
  //    }


  }
  // deviceTurnOn(){
    
  // }
  // deviceConfigMode(){
    
  // }
  // deviceTurnOff(){
    
  // }

  // deviceIdentify(){
    
  // }

  hideTab1(){
    this.modelHide=false;
  }
}
