import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  params: any = {
    EmailAddress: "admin@matoflife.com"
  }
  parameters: any
  constructor(public httpClient: HttpClient, public cookieService:CookieService) { }

  public getAllCustomer() {
    return this.httpClient.get(environment.serviceUrl + 'customer/GetAllDynamicCustomer', { params: this.params });
  }
  public getAll() {
    return this.httpClient.get(environment.serviceUrl + 'customer/GetAllDynamicCustomer', { params: this.params });
  }
  public getDeviceType(DeviceType, id) {
    this.parameters = {
      DeviceType: DeviceType,
      id: id
    }
    return this.httpClient.get(environment.serviceUrl + 'customerdevices/GetAllDeviceTypeDetailByDevice', { params: this.parameters });
  }

  public saveCustomerDevice(o) {
    return this.httpClient.post(environment.serviceUrl + 'customerdevices/SaveCustomerDevice', o)
  }
  public GetAllSMSByMatId(id) {
    this.parameters = {
      id: id
    }
    return this.httpClient.get(environment.serviceUrl + 'customerdevices/GetAllSMSByMatId', { params: this.parameters });
  }
  public GetMatList(id) {
    this.parameters = {
      CustomerMasterId: id
    }

    return this.httpClient.get(environment.serviceUrl + 'customerdevices/GetAllMatDeviceByUser', { params: this.parameters });
  }
  public deleteCustomerDetails(deviceType, id) {

    this.parameters = {
      DeviceType: deviceType,
      id: id
    }
    return this.httpClient.get(environment.serviceUrl + 'customerdevices/DeleteCustomerDevice', { params: this.parameters });
  }

  public removeSmsDetails(id) {

    this.parameters = {
      id: id
    }
    return this.httpClient.get(environment.serviceUrl + 'devicesetting_call_sms/DeleteSMSSetting', { params: this.parameters });
  }
  public getAllUser() {
    //console.log("hello")
    const organizationId = {
      organizationId:this.cookieService.get('organizationId'),
      EmailAddress:this.cookieService.get('EmailAddress'),
      userRole:this.cookieService.get('UserRoles'),
    };
    return this.httpClient.get(environment.serviceUrl + 'customerdevices/GetAllCustomerDeviceByUser', { params:organizationId });
  }
}
