import { Component, OnInit ,Input} from '@angular/core';
import { SellsInfoComponent } from '../../sells-info/sells-info.component';
import { Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-selinfodevice',
  templateUrl: './selinfodevice.component.html',
  styleUrls: ['./selinfodevice.component.css']
})

export class SelinfodeviceComponent implements OnInit {
  customerList:any;
  // @Input() data:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {data: any} ) {
    console.log(this.data)

   }

  ngOnInit(): void {
    // getAllCustomer();
  }




   
    
  }


