import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FirmwareService {
  params:any;
  constructor(public httpClient: HttpClient) { }

  customerDeviceTypeList(params:any) {
    this.params= {
    "DeviceType":params.DeviceType
    }
    return this.httpClient.get(environment.serviceUrl+'firmwareupgrade/GetAllDeviceTypeDetailByDevice',{params:this.params});
  }

  getFileListAsPerDeviceType(params:any) {
    this.params= {
    "DeviceType":params.DeviceType
    }
    return this.httpClient.get(environment.serviceUrl+'firmwareupgrade/GetAllFileByDeviceType',{params:this.params});
  }

  public updateOrganization(rawData){
    
    console.log(rawData)   //console.log(environment.serviceUrl+"organization/updateOrganization/"+id);
    return this.httpClient.post(environment.serviceUrl+"firmwareupgrade/UpgradeByDeviceType", rawData);
  }

  public mobileCustomerDevices(){
    
    return this.httpClient.get(environment.serviceUrl+"mobilecustomerdevices/getconfig");
  }

  public mobileCustomerDevicesUpdate(rawData){
    
    return this.httpClient.post(environment.serviceUrl+"mobilecustomerdevices/updateConfig", rawData);
  }
}
