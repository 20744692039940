import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mat-delete',
  templateUrl: './mat-delete.component.html',
  styleUrls: ['./mat-delete.component.css']
})
export class MatDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  MatDelete(): void {
    alert('Delete');
  }
matdelete():void{
  
}

 

}
