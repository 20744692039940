import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  params:any;
  constructor(public httpClient: HttpClient) { 
  }
  public login(rawData){
    this.params= {
      "Name":rawData.email,
      "Password":rawData.password,
      "deviceToken":rawData.deviceToken
    }
    return this.httpClient.get(environment.serviceUrl+'account/login',{params:this.params});
  }
}
