import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {LinkedDevicesService} from '../../services/linkedDevices/linked-devices.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UserService } from '../../services/user/user.service';
declare var $: any;

export interface DialogData {
  mat_id: string;
  DeviceName: string;
  mat: any;
}

@Component({
  selector: 'app-mat-settings',
  templateUrl: './mat-settings.component.html',
  styleUrls: ['./mat-settings.component.css']
})
export class MatSettingsComponent implements OnInit {
  myModel = true;

  SMSSettings: FormGroup;
  AdvacedSettings: FormGroup;
  list:any;
  matDetails: any;
  dialCode:any="91";

  timer = [
    {value: 10000,title: '10 Seconds'},
    {value: 15000,title: '15 Seconds'},
    {value: 20000,title: '20 Seconds'},
    {value: 25000,title: '25 Seconds'},
    {value: 30000,title: '30 Seconds'},
    {value: 60000,title: '1 Minute'},
    {value: 120000,title: '2 Minute'},
    {value: 180000,title: '3 Minute'},
    {value: 240000,title: '4 Minute'},
    {value: 300000,title: '5 Minute'},
    {value: 360000,title: '6 Minute'},
    {value: 420000,title: '7 Minute'},
    {value: 480000,title: '8 Minute'},
    {value: 540000,title: '9 Minute'},
    {value: 600000,title: '10 Minute'},
    {value: 6300000,title: '15 Minute'},
    {value: 6600000,title: '20 Minute'},
    {value: 1500000,title: '25 Minute'},
    {value: 1800000,title: '30 Minute'},
    {value: 2100000,title: '35 Minute'},
    {value: 2400000,title: '40 Minute'},
    {value: 2700000,title: '45 Minute'},
    {value: 3000000,title: '50 Minute'},
    {value: 3300000,title: '55 Minute'},
    {value: 3600000,title: '1 Hour'},
  ]

  constructor(private formBuilder: FormBuilder, private linkedDevicesService: LinkedDevicesService,
    public dialogRef: MatDialogRef<MatSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public userService: UserService) { 

      // console.log(data);
      this.matDetails = this.data.mat;

      console.log('matDetails--->',this.matDetails);
    }

  ngOnInit(): void {

    this.SMSSettings = this.formBuilder.group({
      
      MobileNo: ['', Validators.required],
      Text_for_1St_Step: ['', Validators.required],
      Text_for_2nd_Step: ['', Validators.required],
      Text_for_Alert_Event: ['', Validators.required],
      Scheduler_StartTime: [''],
      Scheduler_EndTime: [''],
      Scheduling: [''],
      id: [''],
      First_Step:[''],
      Second_Step: [''],
      Alert: [''],
      Device_Mat_Id: this.matDetails.id
  }, { });

  this.AdvacedSettings = this.formBuilder.group({
      MD_Timer: ['', Validators.required],
      Alert_Msg_Timer: ['', ''],
      Standby_Timer: ['', Validators.required],
      LED_Timer: ['', Validators.required],
      Connection_Status: [''],
      CustomerMasterId: [''],
      DeviceName: [''],
      DeviceSerial: [''],
      DeviceType: [''],
      IsActive: [''],
      SMSList: [''],
      SchedulType: [''],
      Setting_Sync_Flag: [''],
      id: ['']
  }, { });

    this.GetAllSMSByMatId(this.data.mat_id);

    this.AdvacedSettings.get('MD_Timer').setValue(this.matDetails.MD_Timer.toString());
    this.AdvacedSettings.get('Alert_Msg_Timer').setValue(this.matDetails.Alert_Msg_Timer.toString());
    this.AdvacedSettings.get('Standby_Timer').setValue(this.matDetails.Standby_Timer.toString());
    this.AdvacedSettings.get('LED_Timer').setValue(this.matDetails.LED_Timer.toString());

    this.AdvacedSettings.get('Connection_Status').setValue(this.matDetails.Connection_Status);
    this.AdvacedSettings.get('CustomerMasterId').setValue(this.matDetails.CustomerMasterId);
    this.AdvacedSettings.get('DeviceName').setValue(this.matDetails.DeviceName);
    this.AdvacedSettings.get('DeviceSerial').setValue(this.matDetails.DeviceSerial);
    this.AdvacedSettings.get('DeviceType').setValue(this.matDetails.DeviceType);
    this.AdvacedSettings.get('IsActive').setValue(this.matDetails.IsActive);
    
    this.AdvacedSettings.get('Setting_Sync_Flag').setValue(this.matDetails.Setting_Sync_Flag);
    this.AdvacedSettings.get('id').setValue(this.matDetails.id);

  }

  SMSSettingsSubmit() {
    
    if (this.SMSSettings.invalid) {
      return;
    }
    // console.log('datta;;;;;;;;;',this.SMSSettings.data.mobileNo )
    // console.log('datta;;;;;;;;;',this.SMSSettings.value.MobileNo'+'+this.dialCode);
    // this.SMSSettings.controls['MobileNo'].setValue('+'+this.dialCode);
    // console.log('datta---->',this.SMSSettings.value);
    this.saveSMSSettings(this.SMSSettings.value);
    
  }

  GetAllSMSByMatId(mat_id){
    this.linkedDevicesService.GetAllSMSByMatId(mat_id).then(result => {
      console.log(result);
      if (result.success) {
        this.list = result.data;
        // this.SMSSettings.get('MobileNo').setValue(this.list[0].MobileNo);
        this.SMSSettings.get('Text_for_1St_Step').setValue(this.list[0].Text_for_1St_Step);
        this.SMSSettings.get('Text_for_2nd_Step').setValue(this.list[0].Text_for_2nd_Step);
        this.SMSSettings.get('Text_for_Alert_Event').setValue(this.list[0].Text_for_Alert_Event);
      
        // this.list.forEach((item, index) =>{
        //   if (item.Scheduler_StartTime != '00:00:00') {
        //     item.Scheduling = 'Scheduling';
        //   }else {
        //     item.Scheduling = 'Always On';
        //     item.Scheduler_EndTime = '00:00:00';
        //   }
        // });
        // this.SMSSettings.get('Scheduler_StartTime').setValue(this.list[0].Scheduler_StartTime);
        // this.SMSSettings.get('Scheduler_EndTime').setValue(this.list[0].Scheduler_EndTime);
        // this.SMSSettings.get('Scheduling').setValue(this.list[0].Scheduling);
      }else {
        console.log(result);
      }
    }).catch(err => console.log(err));
  }

  Edit(edit_item) {
        this.SMSSettings.get('MobileNo').setValue(edit_item.MobileNo);
        this.SMSSettings.get('Text_for_1St_Step').setValue(edit_item.Text_for_1St_Step);
        this.SMSSettings.get('Text_for_2nd_Step').setValue(edit_item.Text_for_2nd_Step);
        this.SMSSettings.get('Text_for_Alert_Event').setValue(edit_item.Text_for_Alert_Event);
        this.SMSSettings.get('Scheduler_StartTime').setValue(edit_item.Scheduler_StartTime);
        this.SMSSettings.get('Scheduler_EndTime').setValue(edit_item.Scheduler_EndTime);
        this.SMSSettings.get('id').setValue(edit_item.id);
        this.SMSSettings.get('Device_Mat_Id').setValue(edit_item.Device_Mat_Id);
        this.SMSSettings.get('First_Step').setValue(edit_item.First_Step);
        this.SMSSettings.get('Second_Step').setValue(edit_item.Second_Step);
        this.SMSSettings.get('Alert').setValue(edit_item.Alert);
  }

  Delete(id) {
    // alert("Delete()");
    console.log(id)
    this.linkedDevicesService.delete(id).subscribe((res)=>{
      // this.userService.showNotification('success', res.message);
      this.showNotification('danger', "SMS Configuration deleted successfully ");

      console.log("delete",res)
    })
  }
  showNotification(Ntype, msg){
    // const type = ['','info','success','warning','danger'];
  
    // const color = Math.floor((Math.random() * 4) + 1);
    // console.log(type[color]);
    $.notify({
        icon: "notifications",
        message: msg
  
    },{
        type: Ntype,
        timer: 200,
        placement: {
            from: 'top',
            align: 'right'
            
        },
        template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert" >' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons" >close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }
  First_Step(values:any, data):void {
    // console.log(values.checked);
    data.First_Step = values.checked;
    this.SMSSettings.get('First_Step').setValue(values.checked);
    this.linkedDevicesService.ChangeSMSSetting(data).then((result) => {
      // console.log(result);
      if (result.success) {
        // console.log(result);
        this.userService.showNotification('success', result.message);
      }else {
        this.userService.showNotification('danger', result.message);
      }
    }).catch(err => console.log(err));
  }

  Second_Step(values:any, data):void {
    // console.log(values.checked);
    data.Second_Step = values.checked;
    this.SMSSettings.get('Second_Step').setValue(values.checked);
    this.linkedDevicesService.ChangeSMSSetting(data).then((result) => {
      // console.log(result);
      if (result.success) {
        // console.log(result);
        this.userService.showNotification('success', result.message);
      }else {
        this.userService.showNotification('danger', result.message);
      }
    }).catch(err => console.log(err));
  }

  Alert_Step(values:any, data):void {
    // console.log(values.checked);
    data.Alert = values.checked;
    this.SMSSettings.get('Alert').setValue(values.checked);
    this.linkedDevicesService.ChangeSMSSetting(data).then((result) => {
      // console.log(result);
      if (result.success) {
        // console.log(result);
        this.userService.showNotification('success', result.message);
      }else {
        this.userService.showNotification('danger', result.message);
      }
    }).catch(err => console.log(err));
  }

  saveSMSSettings(data) {
    
    data.MobileNo= '+'+this.dialCode+data.MobileNo;
    console.log("matdat",data)
    this.linkedDevicesService.AddSMSForMat(data).then((result) => {
      console.log(result);
      if (result.success) {
        // console.log(result);
        this.userService.showNotification('success', result.message);
      }else {
        this.userService.showNotification('danger', result.message);
      }
    }).catch(err => console.log(err));
  }

  AdvacedSettingsSubmit() {
    if (this.AdvacedSettings.invalid) {
      return;
    }
    
    var data = this.AdvacedSettings.value;
    data.SMSList = [];
    data.SchedulType = "Always On";

    console.log(data);
    this.linkedDevicesService.SaveCustomerDevice(data).then((result) => {
      console.log(result);
      if (result.success) {
        // console.log(result);
        this.userService.showNotification('success', result.message);
      }else {
        this.userService.showNotification('danger', result.message);
      }
    }).catch(err => console.log(err));
  }
  telInputObject(obj) {
   
    console.log(obj);
    obj.setCountry('in');
    
  
  }
  getNumber(obj) {
    console.log(obj);
     
  }
  onCountryChange(obj) {
    console.log(obj);
     console.log(obj.dialCode);
     this.dialCode=obj.dialCode;
  }
}
