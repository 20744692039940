// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  serviceUrl:"https://api.matoflife.com/",
  //  serviceUrl:"http://52.36.33.125:3737/",
      // serviceUrl: "http://localhost:3737/",

 firebase: {
  messagingSenderId: "410789206504",
  apiKey: "AIzaSyBVY4SG7IpQrMZJcHfupLhsan9siU9uzPk",
  projectId: "matoflife-e7875",
  appId: "1:410789206504:web:753bb8dc4649256b2bac5d"
}
};
