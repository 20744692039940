import { Component, OnInit, Injectable, Inject } from "@angular/core";
// import { Console } from 'console';
import { Router, ActivatedRoute } from "@angular/router";
import { StepReportService } from "app/services/step-report/step-report.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { AngularFireMessaging } from "@angular/fire/messaging";
// import { Router } from '@angular/router';

import { CookieService } from "ngx-cookie-service";

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { ListKeyManager } from "@angular/cdk/a11y";

@Component({
  selector: "app-track-customer",
  templateUrl: "./track-customer.component.html",
  styleUrls: ["./track-customer.component.css"],
})
export class TrackCustomerComponent implements OnInit {
  close: any = true;
  record: any;
  organizationId = "";
  public Name: any = "";
  UserRoles = this.cookieService.get("UserRoles");
  Email = this.cookieService.get("UserName");
  totalData: [];
  totalData1: [];
  country: any;
  stepsList: any;
  allevnt = false;
  totalRecord: any;
  page: any = 1;
  page1: any = 1;
  pageLength: any = "10";
  events: any = "";
  show;
  any;
  startDate:any;
 endDate:any;
  

  SNo = 1;
  constructor(
    private stepReportService: StepReportService,
    private cookieService: CookieService,
    private router: Router,

    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Name = this.data.item?.tblcustomermaster.Name;
  }

  ngOnInit(): void {
    this.getAllListstep(
      this.page,
      this.Name,
      this.pageLength,
      this.events,
      this.organizationId,
      this.UserRoles,
      this.Email,
      this.startDate,
      this.endDate,
      
    );
    console.log("data--->", this.data.item);
  }
  showClose() {
    this.close = false;
    // this.router.navigate(["/steps-report"]);
  }

  getDataBydeviceName(st) {
    this.stepReportService.getAllDevicename(st).subscribe((res) => {
      console.log(res);
      this.stepsList = res;
      this.totalData = this.stepsList.data.rows;
      this.totalRecord = this.stepsList.data.count;
      console.log(this.totalData);
      if (this.totalRecord == "0") {
        this.record = true;
      } else {
        this.record = false;
      }

      // alert('hiii');
      // console.log(this.page)
      // console.log(this.stepsList.data);
    });

    console.log(st);
  }
  getAllList1(
    evt,
    page1,
    Name,
    limit,
    event,
    organizationId,
    UserRoles,
    Email,
    startDate,
    endDate,
  ) {
    console.log(page1);
    // alert(evt.target.value);

    this.page = 1;
    var start = 0;
    // alert(start);
    // console.log(start)
    this.stepReportService
      .getAllOrderby(
        start,
        Name,
        evt.target.value,
        event,
        organizationId,
        UserRoles,
        Email,
        startDate,
        endDate
      )
      .subscribe((res) => {
        console.log(res);
        this.stepsList = res;
        this.totalData = this.stepsList.data.data;
        this.totalRecord = this.stepsList.data.recordsTotal;
        console.log("xvxcgd", this.stepsList.data[0].Event);

        // console.log(this.page)
        // console.log(this.stepsList.data);
      });
  }

  getAllListstep(page1, Name, limit, event, organizationId, UserRoles, Email,startDate,endDate) {
    // alert(page1);
    // console.log("page1",page1);
    // var p = page1 - 1;
    // if (p > 0) {
    //   this.SNo = p * 10;
    //   this.SNo = this.SNo + 1;
    // } else {
    //   this.SNo = 1;
    // }
    this.page = page1;
    var start = (page1 - 1) * 10;
    console.log(start);
    this.stepReportService
      .getAllOrderby(
        start,
        Name,
        limit,
        event,
        organizationId,
        UserRoles,
        Email,
        startDate,
        endDate
      )
      .subscribe((res) => {
        console.log(res);
        this.stepsList = res;
        this.totalData = this.stepsList.data.data;
        this.totalRecord = this.stepsList.data.recordsTotal;
        console.log("zdfdf", this.stepsList.data.Event);

        if (this.totalRecord == "0") {
          this.record = true;
        } else {
          this.record = false;
        }

        // alert('hiii');
        // console.log(this.page)
        // console.log(this.stepsList.data);
      });
  }
  pageChanged($event) {}
}
