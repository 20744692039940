import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DeviceListService {
   storeData: any;

  UserId= this.cookieService.get('UserId');

  params: any = {
    // columns:[
    //   '0','DESC'
    // ] ,
    search:'',
    order:'',
    start:0,
    length:10,
    draw:1,
    CustomerMasterId:this.UserId
 }
 constructor(public httpClient: HttpClient,    private cookieService:CookieService,
  ) { }
  
  setData(data) {
    this.storeData = data;

  }
  getData() {
    return this.storeData;

  }

 public getAllUser(){
  //  console.log("hello")
   return this.httpClient.get(environment.serviceUrl+'customer/GetAllUser');
 }


 public getAllDynamicCustomerDevicer(data){
  console.log("hello")
  return this.httpClient.get(environment.serviceUrl+'customerdevices/GetAllDynamicCustomerDevice', {params:data});
}
}
