import { Component, OnInit, Inject } from "@angular/core";
import {Ng2TelInputModule} from 'ng2-tel-input';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CustomerService } from "app/services/customer/customer.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
// import { UserService } from '../../services/user/user.service';

declare var $: any;

import {SmsConfigerDeleteComponent} from '../sms-configer-delete/sms-configer-delete.component'
export interface DialogData1 {
  // customerId: any;
  data: any;
  // responseType: any;
  // remarks: any;
}
@Component({
  selector: "app-customer-device",
  templateUrl: "./customer-device.component.html",
  styleUrls: ["./customer-device.component.css"],
})
export class CustomerDeviceComponent implements OnInit {
  submitted = false;
  smsAdd:any;
  smsList: any;
  showMat:any;
  showSocket:any=true;
  showAlarm:any=true;
  Scheduling: any = false;
  Scheduling_SMS = 'Always On';
  showSms: any = false;
  customeralarmDetails= {
    Scheduling: 'Scheduling',
    Scheduler_EndTime: '',
    Scheduler_StartTime: ''
  };
  dialCode:any="91";
  customerData: any;
  Name: any;
  LastName: any;
  CustomerAccountNumber: any;
  Pincode: any;
  EmailAddress: any;
  Type: any;
  date: any;
  configInfo: any;
  userList: any;
  // obj:any;
  GetAllSmsList: any;
   
  GetAllSmsListData: any;
  _headers:any;
  smsId:any;
  // headers:any;
  finalTime:any;
  finalTime2:any
  obj = {
    Alert: 1,
    Device_Faliure: 0,
    Device_Mat_Id: 777,
    First_Step: 1,
    MobileNo: "",
    SchedulType_SMS: "Always On",
    Scheduler_EndTime: "23:59:59:am",
    Scheduler_StartTime: "00:00:00:am",
    Second_Step: 1,
    Text_for_1St_Step: "Client Stepped on Mat.",
    Text_for_2nd_Step: "Client Stepped Back on Mat.",
    Text_for_Alert_Event: "Client didn’t returned.",
    id: "",
  };
  constructor(
    public dialogRef: MatDialogRef<CustomerDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData1,
    private stepReportService: CustomerService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public dialogSms: MatDialog
    // private userService: UserService
  ) {
    // this._headers.append("X-Requested-With", "XMLHttpRequest");
  }

  param: any;
  pararm: any;

  ngOnInit(): void {
    console.log(this.data);

    // this.customeralarmDetails = this.data;
    // this.customeralarmDetails.Alarm_Time = this.customeralarmDetails.Alarm_Time.toString();

    // if (this.obj.Scheduler_StartTime != '00:00:00') {
    //   this.customeralarmDetails.Scheduling = 'Always On';
    // }else {
    //   this.customeralarmDetails.Scheduling = 'Scheduling';
    //   this.customeralarmDetails.Scheduler_EndTime = '00:00:00';
    // }

    this.smsAdd = this.formBuilder.group({
     
      MobileNo: ['',[
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
        Validators.nullValidator
      ]],
      Scheduler_EndTime: ['', ""],
      Scheduler_StartTime: ['', ""],
    
    },
   {});
   
    this._headers = new HttpHeaders(
      {
        "Content-Length": "3733"
  
      
      })

    console.log("dat", this.data);
    this.customerData = this.data.data;
    console.log(this.customerData);
    // console.log(this.customerData.Name)
    // this.Name=this.customerData.Name
    // this.LastName=this.customerData.LastName
    // this.CustomerAccountNumber=this.customerData.CustomerAccountNo
    // this.Pincode=this.customerData.Pincode
    // this.EmailAddress=this.customerData.EmailAddress
    // this.Type=this.customerData.Type
    // this.date=this.customerData.DOB
    this.param = this.customerData.id;
    console.log(this.param);
    this.GetAllSMSByMatId();
  }
  getValue;
  private editData(data){

    console.log(data);
    this.smsId=data.id
    this.obj = {
      Alert: data.Alert,
      Device_Faliure: 0,
      Device_Mat_Id:data.id,
      First_Step: data.First_Step,
      MobileNo:data.MobileNo,
      SchedulType_SMS:data.SchedulType_SMS,
      Scheduler_EndTime:data.Scheduler_EndTime,
      Scheduler_StartTime:data.Scheduler_StartTime,
      Second_Step:data.Second_Step,
      Text_for_1St_Step:data.Text_for_1St_Step,
      Text_for_2nd_Step:data.Text_for_2nd_Step,
      Text_for_Alert_Event: data.Text_for_2nd_Step,
      id:data.id
    };
    this.GetAllSMSByMatId();

  }
  onTimeChange(a:any) {
    // var inputEle = document.getElementById('input');
    console.log(a.value)
    var timeSplit = a.value.split(':'),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
  console.log(timeSplit)
    if (hours > 12) {
      meridian = 'PM';
      hours -= 12;
    } else if (hours < 12) {
      meridian = 'AM';
      if (hours == 0) {
        hours = 12;
      }
    } else {
      meridian = 'PM';
    }
    // alert(hours + ':' + minutes + ' ' + meridian);
    this.finalTime=hours + ':' + minutes + ' ' + meridian
  }
  onTimeChange2(a:any) {
    // var inputEle = document.getElementById('input');
    console.log(a.value)
    var timeSplit = a.value.split(':'),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
  console.log(timeSplit)
    if (hours > 12) {
      meridian = 'PM';
      hours -= 12;
    } 
    else if (hours < 12) {
      meridian = 'AM';
      if (hours == 0) {
        hours = 12;
      }
    } else {
      meridian = 'PM';
    }
    // alert(hours + ':' + minutes + ' ' + meridian);
    this.finalTime2=hours + ':' + minutes + ' ' + meridian
  }
  Scheduling_SMSF(data) {
    this.Scheduling_SMS = data;
    console.log(this.Scheduling_SMS)
  }
  SubmitSave() {
    this.submitted =true;

    if(this.smsAdd.invalid){
   return;
    }
 
  if(this.smsId==undefined){
    this.obj = {
      Alert: 1,
      Device_Faliure: 0,
      Device_Mat_Id: this.param,
      First_Step: 1,
      MobileNo:'+'+this.dialCode+this.obj.MobileNo,
      SchedulType_SMS:this.Scheduling_SMS,
      Scheduler_EndTime: this.finalTime2,
      Scheduler_StartTime: this.finalTime,
      Second_Step: 1,
      Text_for_1St_Step:this.obj.Text_for_1St_Step,
      Text_for_2nd_Step:this.obj.Text_for_2nd_Step,
      Text_for_Alert_Event: this.obj.Text_for_2nd_Step,
      id:''
    };
  }
  else{
    this.obj = {
      Alert: 1,
      Device_Faliure: 0,
      Device_Mat_Id: this.param,
      First_Step: 1,
      MobileNo:'+'+this.dialCode+this.obj.MobileNo,
      SchedulType_SMS:this.Scheduling_SMS,
      Scheduler_EndTime: this.finalTime2,
      Scheduler_StartTime: this.finalTime,
      Second_Step: 1,
      Text_for_1St_Step:this.obj.Text_for_1St_Step,
      Text_for_2nd_Step:this.obj.Text_for_2nd_Step,
      Text_for_Alert_Event: this.obj.Text_for_2nd_Step,
      id:this.smsId
    };
  }
   
    console.log(this.obj);
   // const headers = { abhd: "application" };
    this.http
      .post(environment.serviceUrl + "customerdevices/AddSMSForMat", this.obj, {
        headers:this._headers,
      })
      .subscribe((res) => {

        // console.log(res);
        // this.smsAdd.reset();


        
       this.showNotification('danger', "Updated Successfully");
      //  alert("Updated successfully");

      this.GetAllSMSByMatId();
// debugger;
      this.errorHandling1('MobileNo', 'nullValidator');
      this.smsAdd.reset();


        // alert("Scessfull");
        
        this.submitted =false;

      });
  }

  GetAllSMSByMatId() {
    console.log(this.param);
    localStorage.setItem('getAllsmsid',this.param)
    this.http
      .get(
        environment.serviceUrl +
          "customerdevices/GetAllSMSByMatId?id=" +
          this.param
      )
      .subscribe((res) => {
        
        console.log(res);
        this.GetAllSmsList = res;
        this.GetAllSmsListData = this.GetAllSmsList.data;
        console.log(this.GetAllSmsListData);
      });
  }
  // /customerdevices/GetAllSMSByMatId?id=777
 
private deleteData(list){
  // this.dialogRef.close();
  this.GetAllSMSByMatId();
  const dialogRef = this.dialogSms.open(SmsConfigerDeleteComponent, {
    width: '400px',
    data:list.id
  });

  dialogRef.afterClosed().subscribe(result => {
    this.GetAllSMSByMatId();

    console.log('The dialog was closed');
  });
    //  this.http.get(environment.serviceUrl+'devicesetting_call_sms/DeleteSMSSetting?id'+list.id).subscribe((res)=>{
    //     console.log(res)

    //  })
}
// onCountryChange(event){
//   this.dialCode="+"+event.dialCode
 
// }
public errorHandling1 = (control: string, error: string) => {

  console.log(this.smsAdd.controls[control].hasError(error))
  return this.smsAdd.controls[control].hasError(error);
};

get f() {
  return this.smsAdd.controls;
}
showScheduling() {
  this.Scheduling = true
}
showNothing() {
  this.Scheduling = false
}
showNotification(Ntype, msg){
  // const type = ['','info','success','warning','danger'];

  // const color = Math.floor((Math.random() * 4) + 1);
  // console.log(type[color]);
  $.notify({
      icon: "notifications",
      message: msg

  },{
      type: Ntype,
      timer: 200,
      placement: {
          from: 'top',
          align: 'right'
          
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert" >' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons" >close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
      '</div>'
  });
}
telInputObject(obj) {
   
  console.log(obj);
  obj.setCountry('in');
  

}
getNumber(obj) {
  console.log(obj);
   
}
onCountryChange(obj) {
  console.log(obj);
   console.log(obj.dialCode);
   this.dialCode=obj.dialCode;
}



}
