import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepReportService } from '../../services/step-report/step-report.service';
//import { MdRadioChange } from '@angular/material';


export interface DialogData {
  customerId: any;
  id: any;
  responseType: any;
  remarks: any;
}

@Component({
  selector: 'app-track-modal',
  templateUrl: './track-modal-responded.component.html',
  styleUrls: ['./track-modal-responded.component.css']
})
export class TrackModalRespondedComponent implements OnInit {
  close: any;
  action: any;
  responseType: any = this.data.responseType;
  remarks:any;
  constructor(public dialogRef: MatDialogRef<TrackModalRespondedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private stepReportService: StepReportService) { }

  ngOnInit(): void {
    this.action = "will get it after api integration"
    if (this.data.responseType === 1) {
      this.responseType = "Call to attendent"
    }
    else if (this.data.responseType === 2) {
      this.responseType = "Line up healthcare person"
    }
    else {
      this.responseType = `Other option:`
    }
    this.remarks = this.data.remarks
  }
  //   Call to attendent Line
  //  up healthcare person
  //  Other option if have

  showClose() {
    this.close = false
    console.log(this.responseType);

  }
}
