import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler,HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router,ActivatedRoute,  NavigationEnd, NavigationStart } from '@angular/router';
import {LoaderService} from "../services/loader/loader.service"
import {finalize} from 'rxjs/operators'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cookieService:CookieService, private router:Router, public LoaderService:LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.cookieService.get('token');; // you probably want to store it in localStorage or something
    // console.log(token);
    this.LoaderService.isLoading.next(true)

    if (!token) {
      return next.handle(req);
    }
    if(this.router.url =="/user-profile"){
        var tablename = "Profile Details";
    }else if(this.router.url == '/linked-devices'){
        var tablename = "Linked Devices";
    }else if(this.router.url =="/firmware-upgrade"){
        var tablename = "Firmware Upgrade";
    }else if(this.router.url =="/manage-module"){
        var tablename = "Roles";
    }else if(this.router.url =="/steps-report"){
        var tablename = "Track Event";
    }
    else if(this.router.url =="/customer"){
      var tablename = "Customer";
  }
    else if(this.router.url =="/customer-service"){
      var tablename = "Customer Service";
  }else{
        var tablename = "";
    }
   // alert(tablename);
    
    const req1 = req.clone({
      headers:
            new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': `${token}`,
              'X-Requested-With':tablename
            })
    });

    return next.handle(req1).pipe(
      finalize(
        ()=>{
          console.log("request",req1)
          this.LoaderService.isLoading.next(false)
          

        }
      )
    );
  }

}