import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import "rxjs/add/operator/map";
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public httpClient: HttpClient) { }

  getProfileDetails(id) {
    
    var params = {
      id: id
    };

    return new Promise<any>((resolve) => {
      this.httpClient
        .get(
          environment.serviceUrl + "account/GetCustomereById",
          { params: params }
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  UpdateCustomerProfile(data: any) {

    
    return new Promise<any>((resolve) => {
      this.httpClient
        .post(
          environment.serviceUrl + "account/UpdateCustomer",
          data
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  showNotification(Ntype, msg){
      // const type = ['','info','success','warning','danger'];

      // const color = Math.floor((Math.random() * 4) + 1);
      // console.log(type[color]);
      $.notify({
          icon: "notifications",
          message: msg

      },{
          type: Ntype,
          timer: 2000,
          placement: {
              from: 'top',
              align: 'right'
          },
          template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
            '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">notifications</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
  }

  public GetMobileLanguageData() {
    return new Promise<any>((resolve) => {
      this.httpClient
        .get(
          environment.serviceUrl + "customer/GetMobileLanguageData"
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  uploadImage(formData){
    return new Promise<any>((resolve) => {
      this.httpClient
        .post(
          environment.serviceUrl + "account/uploadImage",formData, { headers: { 'Content-Type': undefined }}
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
    
  }

}
