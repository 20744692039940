import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepReportService } from '../../services/step-report/step-report.service';
//import { MdRadioChange } from '@angular/material';


export interface DialogData {
  customerId: any;
  id: any;
}
@Component({
  selector: 'app-track-modal',
  templateUrl: './track-modal.component.html',
  styleUrls: ['./track-modal.component.css']
})
export class TrackModalComponent implements OnInit {
  customerMasterId: any;
  details: any;
  id: any;
  name: any;
  mobile: any;
  Attendent: any = false;
  health: any = false;
  other: any = false;
  close: any = true;
  responseType: any;
  remarks: any = "";
  pincode: any;
  showError: any = "";
  constructor(public dialogRef: MatDialogRef<TrackModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private stepReportService: StepReportService) { }

  ngOnInit(): void {
    this.customerMasterId = this.data.customerId;
    this.id = this.data.id;
    console.log(this.id);
    // console.log(this.customerMasterId);
    this.getCustomerDetails();
  }

  private getCustomerDetails() {
    let rawData = {
      customerMasterId: this.customerMasterId
    }
    this.stepReportService.getCustomerDeatils(rawData).subscribe((res) => {
      this.details = res;
      
      var go = (this.details.ContactNo).toString()

      var contact="";
      var length=go.length;
      var len =go.length;
      for (var i = 0; i < length; i++) {
        if (i === 3 || i === 6 || i === 9) {
          len=len-1
          contact = "-" + go.charAt(len) + contact
        }else{
          len=len-1
          contact = go.charAt(len) + contact
        }        
      }
      this.mobile = contact;
      this.name = this.details.Name;
      this.pincode = this.details.Pincode;
      // console.log(this.details);
    });
  }
  showAttendent(value) {
    this.Attendent = true
    this.other = false;
    this.health = false;
    this.responseType = 1;
    console.log(this.responseType)
  }
  showhealthcare(value) {
    this.health = true;
    this.Attendent = false;
    this.other = false;
    this.responseType = 2;
    console.log(this.responseType)
  }
ifothervalue(value) {
  debugger
    this.health = false;
    this.Attendent = false;
    this.other = false;
    this.remarks=true
    this.responseType = 4;
    console.log(this.responseType)
}
  showOther(value) {
    this.health = false;
    this.other = true;
    this.Attendent = false;
    this.responseType = 3;
    console.log(this.responseType)
  }
  showClose() {
    this.close = false
    console.log(this.responseType);

  }
  onSubmit(alertform) {
    this.close = true;

    // console.log(alertform.value);
    console.log(this.responseType);
    if (this.responseType == undefined) {
      this.showError = 'Please fill any option';
      return;
    }
    // return;
    if (this.responseType == 3 && alertform.value.remarks == '') {
      this.showError = 'Please fill remarks';
      return;
    } else {
      this.dialogRef.close();
      this.showError = '';
    }
    // console.log(alertform.value.remarks);
    // console.log(this.id);
    var date=new Date()
    debugger
    this.stepReportService.updateLogReport(alertform.value.remarks, this.responseType, this.id, date).subscribe((res) => {
      console.log("success" + res)

    })
    // window.location.reload();
  }
  // radioChange(event: any) {
  //   // this.filter['property'] = event.value;
  //   // console.log(this.filter);
  //   this.responseType = event.target;
  //   // console.log(this.responseType)
  // }

}
