import { Component, OnInit,Inject } from '@angular/core';
import { LinkedDevicesService } from '../../services/linkedDevices/linked-devices.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UserService } from '../../services/user/user.service';

export interface DialogData {
  MatId: number;
}

@Component({
  selector: 'app-link-devices',
  templateUrl: './link-devices.component.html',
  styleUrls: ['./link-devices.component.css']
})
export class LinkDevicesComponent implements OnInit {
  unlinkDevices:any;
  LinkDeviceList = [];
  constructor( public linkedDevicesService: LinkedDevicesService,public dialogRef: MatDialogRef<LinkDevicesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public userService: UserService) {
      console.log(data);
    }

  ngOnInit(): void {
    this.GetAllInavtiveSocketDevice();
  }

  GetAllInavtiveSocketDevice() {
    this.linkedDevicesService.GetAllInavtiveSocketDevice().then((result) => {
      console.log(result);
      if (result.success) {
        this.unlinkDevices = result.data;
      }else {

      }
    }).catch(err => console.log(err));
  }

  linkDevicesChange(event, device) {
    // console.log(event.checked);
    let deviceName = device.DeviceName.toLowerCase();
    // console.log(deviceName.indexOf('lamp-'));
    if (deviceName.indexOf('lamp-') === 0) {
      device.DeviceType = "Socket";
    }else if (deviceName.indexOf('alarm-') === 0){
      device.DeviceType = "Alarm";
    }
    
    if (event.checked === true) {
      this.LinkDeviceList.push(device);
    }else{
      this.LinkDeviceList.forEach((item, index) =>{
        if (item.id === device.id) {
          // console.log("splice");
          this.LinkDeviceList.splice(index,1);
        }
      });
    }
    
  }

  linkSelectedDevice() {
    // console.log(this.LinkDeviceList, this.data.MatId);
    this.linkedDevicesService.LinkDeviceswithMat(this.LinkDeviceList, this.data.MatId).then((result)=>{
      console.log(result);
      if (result.success) {
        this.userService.showNotification('success', result.message);
        window.location.reload();
      }else {
        this.userService.showNotification('danger', result.message);
      }
    }).catch(err => console.log(err));
  }

}
