import { Component, OnInit } from '@angular/core';
import { CheckRightsService } from '../../services/checkRights/check-rights.service';
import { Router } from '@angular/router';
import { CookieService } from "ngx-cookie-service";
import { AdminroleService } from 'app/services/adminrole.service';



declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  subMenu:any;
  anchorClass:any;
  anchorLink:any;
}
export let ROUTES: RouteInfo[] = [
];


// export const ROUTES: RouteInfo[] = [
//   { path: '/customer', title: 'Customer',  icon: 'dashboard', class: '',subMenu:'',anchorLink:"",anchorClass:'nav-link' },
//   { path: '/user-profile', title: 'User Profile',  icon: 'dashboard', class: '',subMenu:'',anchorLink:"", anchorClass:'nav-link' },
//   { path: '', title: 'Users',  icon:'person', class: 'dropdown',anchorLink:"#role",anchorClass:'nav-link dropdown-toggle',
//     subMenu:[
//       {path: '/manage-module', title: 'Manage Module',  icon:'library_books', class: '',anchorLink:"", anchorClass:'nav-link'},
//       {path: '/roles', title: 'Roles',  icon:'library_books', class: '',anchorLink:"", anchorClass:'nav-link'},
//       {path: '/user-permission', title: 'User Permission',  icon:'library_books', class: '',anchorLink:"", anchorClass:'nav-link'}
//     ]
//   },
//   { path: '/organization', title: 'Organization',  icon:'library_books', class: '' ,subMenu:'',anchorLink:"", anchorClass:'nav-link' },
//   { path: '/firmware-upgrade', title: 'Firmware Upgrade',  icon:'library_books', class: '', subMenu:'',anchorLink:"", anchorClass:'nav-link' },
//   { path: '/linked-devices', title: 'Linked Devices',  icon:'library_books', class: '',subMenu:'',anchorLink:"", anchorClass:'nav-link' },
// ];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  roletype:any
  menuItems: any[];
  response: any;
  tablename:any;
  link:any="../../../assets/img/";
  suffix:any=".svg"

  constructor(private checkRights:CheckRightsService,private AdminroleService :AdminroleService,private router:Router,    private cookieService: CookieService,
    ) { }

  ngOnInit() {
    this.roletype=this.cookieService.get('UserRoles');
    console.log("roltype",this.roletype)
    //this.menuItems = ROUTES.filter(menuItem => menuItem);
    //console.log(this.menuItems);
    this.checkAllPageRights();
    if(this.router.url =="/user-profile"){
      this.tablename = "Profile Details";
    }else if(this.router.url == '/linked-devices'){
        this.tablename = "Linked Devices";
    }else if(this.router.url =="/firmware-upgrade"){
        this.tablename = "Firmware Upgrade";
    }
    else if(this.router.url =="/firmware-upgrade"){
      this.tablename = "manage-module";
   }
   else if(this.router.url =="/unlinked-devices"){
    this.tablename = "Unlinked Devices";
   }   
    else{
        this.tablename = "";
    }
    this.checkPageRights();
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
  public async checkPageRights(){
    console.log("table",this.tablename);
    if(this.tablename!=""){
      this.response = await this.checkRights.checkPageRights(this.tablename);
      if(this.response.data == null){
       // this.router.navigate(['/user-profile'])
      }
    }
  }
  public async checkAllPageRights(){
    console.log(ROUTES);
    this.response = await this.checkRights.checkAllRights();
    console.log(this.response);
    ROUTES = await  this.checkRights.setMenue(this.response);
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    if(this.cookieService.get('UserRoles')=="User" ) {
      this.response = await this.AdminroleService.checkAllRights();
      console.log(this.response);
      ROUTES = await  this.AdminroleService.setMenue(this.response);
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    else{
      this.cookieService.get('User')=="User" 
      
    }

        
    


    console.log("rout",ROUTES);
  }
}
