import { Component} from '@angular/core';
import { MessagingService } from './services/messaging/messaging.service';
import { AngularFireMessaging } from '@angular/fire/messaging';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor( private messagingService: MessagingService){

  }
  ngOnInit(): void {
    this.messagingService.receiveMessage();
  }
}
