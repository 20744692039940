import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerDevicesComponent } from '../../customer-devices/customer-devices.component';
import { CustomerService } from '../../services/customer/customer.service'
export interface DialogData {
  // customerId: any;
   id: any;
   device:any
  // responseType: any;
  // remarks: any;
}
@Component({
  selector: 'app-delete-device',
  templateUrl: './delete-device.component.html',
  styleUrls: ['./delete-device.component.css']
})
export class DeleteDeviceComponent implements OnInit {


 
  id: any;
  deviceType:any
  constructor(public dialogRef: MatDialogRef<DeleteDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private stepReportService: CustomerDevicesComponent,   private customerService: CustomerService,) { }

  ngOnInit(): void {
    this.id=this.data.id
    this.deviceType=this.data.device
    console.log("data"+this.deviceType)
  }
  Yes(){
    this.customerService.deleteCustomerDetails(this.deviceType,this.id).subscribe((res) => {
      console.log(res)  
      this.dialogRef.close();
    })
  }

  resetpage(){
        this.customerService.deleteCustomerDetails
  }

}
