import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class CheckRightsService {
  roletype:any
  constructor(public httpClient: HttpClient,private cookieService: CookieService,
    ) { }
 
  public async checkAllRights(){
    return await this.httpClient.get(environment.serviceUrl+'userPermission/GetAllPageRights').toPromise();
  }
  public async getAllRole(){
    return await this.httpClient.get(environment.serviceUrl+'role/GetAllRole').toPromise();
  }
  public async getAllModule(){
    return await this.httpClient.get(environment.serviceUrl+'module/GetAllModule').toPromise();
  }
  public async saveRole(rawData){
    return this.httpClient.post(environment.serviceUrl+'role/SaveRole',rawData).toPromise();
  }
  delete(idRole){
    return this.httpClient.get(environment.serviceUrl+"role/DeleteRole?idRole="+idRole)
  }
 
  public async changePermission(rawData){
    return this.httpClient.post(environment.serviceUrl+'userPermission/ChangePermission',rawData).toPromise();
  }

  public async getPermissionUserWise(rawData){
    var params= {
      "RoleName":rawData.roleType
    }
    return this.httpClient.get(environment.serviceUrl+'userPermission/GetAllPermissionByRole',{params:params}).toPromise();
  }
  public async checkPageRights(tablename){
    var params= {
      "permission":"Show",
      "tablename":tablename
    }
    return await this.httpClient.get(environment.serviceUrl+'userPermission/CheckRightsbyPage',{params:params}).toPromise();
  }
  public async setMenue(menue){
    // var lstCustomerDevice = menue.filter( it => {
    //   return it.tblmodulemgmt.Module ='Customer Devices';
    // });
    var menueList = [];
    var flag = false;
    var user      = { path: '', title: 'Users',  icon:'../../../assets/img/user.svg', class: 'dropdown',anchorLink:"#role",anchorClass:'nav-link dropdown-toggle',
        subMenu:[
        ]
      };
    var lstUserPermission = menue.data.filter(function(item) {
        if(item.tblmodulemgmt.Module =='User Permission'){
          return item;
        }
    });
    if(lstUserPermission.length>0){
      user.subMenu.push({path: '/userPermission', title: 'User Permission',  icon:'../../../assets/img/library_books.svg', class: '',anchorLink:"", anchorClass:'nav-link'});
      flag = true;
    }
    var lstManageModule = menue.data.filter(function(item) {
      if(item.tblmodulemgmt.Module =='Manage Module'){
        return item;
      }
    });
    if(lstManageModule.length>0){
      user.subMenu.push({path: '/manage-module', title: 'Manage Module',  icon:'../../../assets/img/view_module_black_24dp.svg', class: '',anchorLink:"", anchorClass:'nav-link'});
    }

   
    
    // var lstRole = menue.data.filter(function(item) {
    //   if(item.tblmodulemgmt.Module =='Roles'){
    //     return item;
    //   }
    // });
    // if(lstRole.length>0){
    //   user.subMenu.push({path: '/roles', title: 'Roles',  icon:'library_books', class: '',anchorLink:"", anchorClass:'nav-link'});
    // }
    var lstCustomer = menue.data.filter(function(item) {
      if(item.tblmodulemgmt.Module =='Customer'){
        return item;
      }
    });
    // if(lstCustomer.length>0){
      menueList.push({ path: '/CustomerDevices', title: 'Customer Device',  icon: '../../../assets/img/customerdevice.png', class: '',subMenu:'',anchorLink:"",anchorClass:'nav-link' });
    // }
    if(flag){
      menueList.push(user);
    }
    var lstCustomerDevice = menue.data.filter(function(item) {
      if(item.tblmodulemgmt.Module =='Customer Devices'){
        return item;
      }
    });
    
    var lstProfileDetails = menue.data.filter(function(item) {
      if(item.tblmodulemgmt.Module =='Customer'){
        return item;
      }
    });
    if(lstProfileDetails.length>0){
      menueList.push({ path: '/customer', title: 'Customer',  icon: '../../../assets/img/customer.png', class: '',subMenu:'',anchorLink:"", anchorClass:'nav-link' });
    }
    var lstProfileDetails = menue.data.filter(function(item) {
      if(item.tblmodulemgmt.Module =='Profile Details'){
        return item;
      }
    });
    if(lstProfileDetails.length>0){
      menueList.push({ path: '/user-profile', title: 'User Profile',  icon: '../../../assets/img/userfrofile.png', class: '',subMenu:'',anchorLink:"", anchorClass:'nav-link' });
    }
    var lstLinkedDevices = menue.data.filter(function(item) {
      if(item.tblmodulemgmt.Module =='Linked Devices'){
        return item;
      }
    });
    menueList.push({ path: '/device-details', title: 'Device Details',  icon: '../../../assets/img/devicedetails.png', class: '',subMenu:'',anchorLink:"",anchorClass:'nav-link' });
    
    if(lstLinkedDevices.length>0){
      menueList.push({ path: '/linked-devices', title: 'Linked Devices',  icon:'../../../assets/img/link_black_24dp.svg', class: '',subMenu:'',anchorLink:"", anchorClass:'nav-link' });
    }
    var lstUnlinkedDevices = menue.data.filter(function(item) {
      if(item.tblmodulemgmt.Module =='Unlinked Devices'){
        return item;
      }
    });
    if(lstUnlinkedDevices.length>0){
      menueList.push({ path: '/unlinked-devices', title: 'Unlinked Devices',  icon:'../../../assets/img/link_off_black_24dp (1) (1).svg', class: '',subMenu:'',anchorLink:"", anchorClass:'nav-link' });
    }
    var lstFirmwareUpgrade = menue.data.filter(function(item) {
      if(item.tblmodulemgmt.Module =='Firmware Upgrade'){
        return item;
      }
    });
    if(lstFirmwareUpgrade.length>0){
      menueList.push({ path: '/firmware-upgrade', title: 'Firmware Upgrade',  icon:'../../../assets/img/firmware.svg', class: '',subMenu:'',anchorLink:"", anchorClass:'nav-link' });
    }
    menueList.push({path: '/organization', title: 'organization',  icon:'../../../assets/img/organisation.png', class: '',anchorLink:"", anchorClass:'nav-link'});
    menueList.push({path: '/customer-organization', title: 'Customer Organization',  icon:'../../../assets/img/customerorganisaton.png', class: '',anchorLink:"", anchorClass:'nav-link'});
    // menueList.push({path: '/foot-steps', title: 'Foot Steps',  icon:'../../../assets/img/footstep.png', class: '',anchorLink:"", anchorClass:'nav-link'});
    menueList.push({path: '/steps-report', title: 'Track Event',  icon:'../../../assets/img/track.png', class: '',anchorLink:"", anchorClass:'nav-link'});
    menueList.push({ path: '/notification', title: 'Notification',  icon: '../../../assets/img/notifications_black_24dp (1).svg', class: '',subMenu:'',anchorLink:"",anchorClass:'nav-link' });
    menueList.push({ path: '/sales-info', title: 'Sales Info',  icon: '../../../assets/img/selinfo.png', class: '',subMenu:'',anchorLink:"",anchorClass:'nav-link' });
    menueList.push({ path: '/customer-service', title: 'Customer Service',  icon: '../../../assets/img/customerservice.png', class: '',subMenu:'',anchorLink:"",anchorClass:'nav-link' });
    // menueList.push({ path: '/service-mngt', title: 'Service Management',  icon: '../../../assets/img/track.svg', class: '',subMenu:'',anchorLink:"",anchorClass:'nav-link' });
    // menueList.push({ path: '/service-subscribe', title: 'Service Subscriber',  icon: '../../../assets/img/track.svg', class: '',subMenu:'',anchorLink:"",anchorClass:'nav-link' });
    menueList.push({ path: '/app-version', title: 'app-version',  icon: '../../../assets/img/appversion.png', class: '',subMenu:'',anchorLink:"",anchorClass:'nav-link' });

      return menueList;
    }
    
}
