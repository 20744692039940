import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LinkedDevicesService } from '../../services/linkedDevices/linked-devices.service';
import { UserService } from '../../services/user/user.service';

export interface DialogData {
  lamp: any;
}

@Component({
  selector: 'app-lamp-settings',
  templateUrl: './lamp-settings.component.html',
  styleUrls: ['./lamp-settings.component.css']
})
export class LampSettingsComponent implements OnInit {
  LampSettings: FormGroup;
  timer = [
    {value: 10000,title: '10 Seconds'},
    {value: 15000,title: '15 Seconds'},
    {value: 20000,title: '20 Seconds'},
    {value: 25000,title: '25 Seconds'},
    {value: 30000,title: '30 Seconds'},
    {value: 60000,title: '1 Minute'},
    {value: 120000,title: '2 Minute'},
    {value: 180000,title: '3 Minute'},
    {value: 240000,title: '4 Minute'},
    {value: 300000,title: '5 Minute'},
    {value: 360000,title: '6 Minute'},
    {value: 420000,title: '7 Minute'},
    {value: 480000,title: '8 Minute'},
    {value: 540000,title: '9 Minute'},
    {value: 600000,title: '10 Minute'},
    {value: 6300000,title: '15 Minute'},
    {value: 6600000,title: '20 Minute'},
    {value: 1500000,title: '25 Minute'},
    {value: 1800000,title: '30 Minute'},
    {value: 2100000,title: '35 Minute'},
    {value: 2400000,title: '40 Minute'},
    {value: 2700000,title: '45 Minute'},
    {value: 3000000,title: '50 Minute'},
    {value: 3300000,title: '55 Minute'},
    {value: 3600000,title: '1 Hour'},
  ];

  lampDetails: any;

  constructor(public dialogRef: MatDialogRef<LampSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public linkedDevicesService: LinkedDevicesService,public userService: UserService) { }

  ngOnInit(): void {

    console.log(this.data);
    this.lampDetails = this.data.lamp;
    this.lampDetails.LightOff_Timer = this.lampDetails.LightOff_Timer.toString();

    if (this.lampDetails.Scheduler_StartTime != '00:00:00') {
      this.lampDetails.Scheduling = 'Scheduling';
    }else {
      this.lampDetails.Scheduling = 'Always On';
      this.lampDetails.Scheduler_EndTime = '00:00:00';
    }
  }

  LampSettingsSubmit(data) {
    // console.log(data);

    this.linkedDevicesService.SaveCustomerDevice(data).then((result) => {
      console.log(result);
      if (result.success) {
        // console.log(result);
        this.userService.showNotification('success', result.message);
      }else {
        this.userService.showNotification('danger', result.message);
      }
    }).catch(err => console.log(err));
  }

}
