import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RegisterService } from "../services/register/register.service";
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: "app-forgetpassword",
  templateUrl: "./forgetpassword.component.html",
  styleUrls: ["./forgetpassword.component.css"],
})
export class ForgetpasswordComponent implements OnInit {
  forgetpasswordemail: FormGroup;
  forgetpasswordmobile: FormGroup;
  checked = true;
  passwordhide = false;
  radiovalue;
  loginInvalid:any;
  currentbtn = true;
  resetmessage:any;
  resetviewshow=false;
  resetviewshowusenumber=false;
  hideresertpasswordview=true;

  constructor(
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
   private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    this.forgetpasswordemail = this.formBuilder.group({
      email: [],
    });
    this.forgetpasswordmobile = this.formBuilder.group({
      MobileNo: "",
    });
  }

  onEmailSubmit(forgetpasswordemail: FormGroup) {
    debugger
    var checkemailvalid = forgetpasswordemail.value.email;
    console.log(checkemailvalid);
    var checkemail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    this.hideresertpasswordview=false
    if (checkemailvalid.match(checkemail)) {
      
      this.registerService
        .resetpasswordUsedemail(checkemailvalid)
        .subscribe((res) => {
         
        this.resetmessage=res;
        this.resetmessage=this.resetmessage.data
        this.cookieService.set('UniqueNumber', this.resetmessage.UniqueNumber);
        console.log(this.resetmessage);
        this.resetviewshow=true
        });

      return false;
    }
  }

  onPassworSubmit(forgetpasswordmobile: FormGroup) {
    
    var checkeMobileNovalid = "%2B" + forgetpasswordmobile.value.MobileNo;
    console.log(checkeMobileNovalid);
    this.hideresertpasswordview=false
    this.resetviewshowusenumber=true
   // var checkNumber = /^(?=.*[0-9])[- +()0-9]+$/;
    // if (checkeMobileNovalid.match(checkNumber)) {
      alert("ok");
      // var convertnumberTostring=checkeMobileNovalid.toString()
      //console.log("convertnumberTostring"+convertnumberTostring);
      // this.registerService
      //   .resetpasswordUsednumber(checkeMobileNovalid)
      //   .subscribe((res) => {
      //     console.log(res);
      //   });

      return false;
    // }
  }

  onItemChange(value) {
    this.radiovalue = value;
    console.log(" Value is : ", this.radiovalue.value);
    if (this.radiovalue.value == 2) {
      this.checked = false;
      this.passwordhide = true;
    }
    if (this.radiovalue.value == 1) {
      this.passwordhide = false;
      this.checked = true;
    }
  }

  onchangebtn(e) {
    console.log(e.value.email);
    this.currentbtn = false;
  }
}
