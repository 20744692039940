import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  Service: any;
  Data: any;
}

@Component({
  selector: 'app-rate-model',
  templateUrl: './rate-model.component.html',
  styleUrls: ['./rate-model.component.css']
})
export class RateModelComponent implements OnInit {
  listData: any;
  Service:any

  constructor(public dialogRef: MatDialogRef<RateModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.loadData()
  }
  loadData = () => {
 this.Service=this.data.Service;
 this.listData=this.data.Data;
  }
}
