import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { environment } from 'environments/environment';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
declare var $: any;

import {CustomerDeviceComponent} from '../../modals/customer-device/customer-device.component';
export interface DialogData1 {
  // customerId: any;
  data: any;
  // responseType: any;
  // remarks: any;
}
@Component({
  selector: 'app-sms-configer-delete',
  templateUrl: './sms-configer-delete.component.html',
  styleUrls: ['./sms-configer-delete.component.css']
})
export class SmsConfigerDeleteComponent implements OnInit {
param:any;
GetAllSmsList:any;
GetAllSmsListData:any
  constructor( public dialogRef: MatDialogRef<SmsConfigerDeleteComponent>,  @Inject(MAT_DIALOG_DATA) public data: DialogData1,private http:HttpClient) { }

  ngOnInit(): void {
    console.log(this.data)
    this.param=localStorage.getItem('getAllsmsid');
    
  }

  cancelDelate(){

  
    
  }
  
  GetAllSMSByMatId() {
    console.log(this.param);
    this.http
      .get(
        environment.serviceUrl +
          "customerdevices/GetAllSMSByMatId?id=" +
          this.param
      )
      .subscribe((res) => {
        console.log(res);
        this.GetAllSmsList = res;
        this.GetAllSmsListData = this.GetAllSmsList.data;
        console.log(this.GetAllSmsListData);
      });
  }
  deleteSmsmat(){
    console.log("hgvhgv",this.data);   
     this.http.get(environment.serviceUrl+'devicesetting_call_sms/DeleteSMSSetting?id='+this.data).subscribe((res)=>{
      console.log(res)
      this.showNotification('danger', "Delete Successfully");

      this.dialogRef.close();
      this.GetAllSMSByMatId();

   })
  }
  showNotification(Ntype, msg){
    // const type = ['','info','success','warning','danger'];
  
    // const color = Math.floor((Math.random() * 4) + 1);
    // console.log(type[color]);
    $.notify({
        icon: "notifications",
        message: msg
  
    },{
        type: Ntype,
        timer: 200,
        placement: {
            from: 'top',
            align: 'right'
            
        },
        template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert" >' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons" >close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }
}
