import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import "rxjs/add/operator/map";
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class LinkedDevicesService {

  constructor(public httpClient: HttpClient, public cookieService: CookieService) { }

  GetAllCustomerDeviceByUser() {
    
    var params = {
      EmailAddress: this.cookieService.get('UserName')
    };

    return new Promise<any>((resolve) => {
      this.httpClient
        .get(
          environment.serviceUrl + "customerdevices/GetAllCustomerDeviceByUser",
          { params: params }
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }
  delete(id){
    return this.httpClient.get(environment.serviceUrl+"devicesetting_call_sms/DeleteSMSSetting?id="+id)
  }
  GetAllSMSByMatId(mat_id) {
    var params = {
      id: mat_id
    };
    // console.log(params);
    return new Promise<any>((resolve) => {
      this.httpClient
        .get(
          environment.serviceUrl + "customerdevices/GetAllSMSByMatId",
          { params: params }
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  
  AddSMSForMat(body) {
    return new Promise<any>((resolve) => {
      this.httpClient
        .post(
          environment.serviceUrl + "customerdevices/AddSMSForMat",
          body
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  

  ChangeSMSSetting(body) {
    return new Promise<any>((resolve) => {
      this.httpClient
        .post(
          environment.serviceUrl + "customerdevices/ChangeSMSSetting",
          body
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  SaveCustomerDevice(body) {
    // customerdevices/SaveCustomerDevice
    // console.log(body);
    return new Promise<any>((resolve) => {
      this.httpClient
        .post(
          environment.serviceUrl + "customerdevices/SaveCustomerDevice",
          body
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  GetAllInavtiveSocketDevice() {
    var params = {
      CustomerMasterId: this.cookieService.get('UserId')
    };

    return new Promise<any>((resolve) => {
      this.httpClient
        .get(
          environment.serviceUrl + "customerdevices/GetAllInavtiveSocketDevice",
          { params: params }
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  LinkDeviceswithMat(LinkDeviceList, MatId) {
    var body = {
      LinkDeviceList: LinkDeviceList,
      MatId: MatId
    };

    return new Promise<any>((resolve) => {
      this.httpClient
        .post(
          environment.serviceUrl + "customerdevices/LinkDeviceswithMat",
          body
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  GetAllMatDeviceByUser() {

    var params = {
      CustomerMasterId: this.cookieService.get('UserId')
    };

    return new Promise<any>((resolve) => {
      this.httpClient
        .get(
          environment.serviceUrl + "customerdevices/GetAllMatDeviceByUser",
          { params: params}
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  UnlinkDevicesFromMat(body) {
    body.EditFlag = false;
    body.MatId = null;
    return new Promise<any>((resolve) => {
      this.httpClient
        .post(
          environment.serviceUrl + "customerdevices/UnlinkDevicesFromMat",
          body
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  GetAllCordlessDevice(MatId) {
    var params = {
      MatId: MatId
    };

    return new Promise<any>((resolve) => {
      this.httpClient
        .get(
          environment.serviceUrl + "customerdevices/GetAllCordlessDevice",
          { params: params}
        )
        .map((res) => res)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

}
