import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class StepReportService {
  constructor(public httpClient: HttpClient) { }
  params: any = {
    columns:[
      '0','DESC'
    ] ,
    search:{
      value:''
    },
    order:'',
 }
 public getAllReports(start,Name,Limit,event){
   console.log("entered"+ start +" "+ Name +" "+ Limit)
   return this.httpClient.post(environment.serviceUrl+'mobilecustomerdevices/getAllEvents', {offset:start,limit:parseInt(Limit),search:Name,event:event});
 }
 public getAllOrderby(start,Name,Limit,event,organizationId,UserRoles,Email,startDate,endDate){
  // console.log("entered"+ start +" "+ Name +" "+ Limit)
  return this.httpClient.post(environment.serviceUrl+'mobilecustomerdevices/getAllEventsOrderByLimit', {offset:start,limit:parseInt(Limit),search:Name,event:event,organizationId:organizationId,UserRoles:UserRoles,Email:Email,startDate:startDate,endDate:endDate});
}
public getAllOrdermonthby(start,Name1,Limit1,eventType,date,organizationId,startdate,enddate){
  console.log("entered"+ start +" "+ Name1 +" "+ Limit1)
  return this.httpClient.post(environment.serviceUrl+'mobilecustomerdevices/getAllMonthEventsOrderByLimit', {offset:start,limit:parseInt(Limit1),search:Name1,event:eventType,date:date,organizationId:organizationId,startdate:startdate,enddate:enddate});
}
public getAllDevicename(DeviceName){
  // console.log("entered"+ start +" "+ Name +" "+ Limit+""+devicetype)
  return this.httpClient.post(environment.serviceUrl+'mobilecustomerdevices/getAllEventsByDeviceName', {DeviceName:DeviceName});
}
 public sendNotification(rawData){
  return this.httpClient.post('https://chatadocuat.com/mobilepromotions/sendNotificationToUser',rawData);
 }
 public getCustomerDeatils(rawData){
  return this.httpClient.post(environment.serviceUrl+'customer/getCustomerDetailById',rawData);
 }
 public updateLogReport(remarks,responseType,id,date){
   console.log("sent"+" "+remarks+" "+responseType+" "+id);
  return this.httpClient.post(environment.serviceUrl+'customer/updateLogStatus',{remarks:remarks,id:id,responseType:responseType,dateTime:date})
 }
 public getFootDataPoints(){
  return this.httpClient.get(environment.serviceUrl+'mobilecustomerdevices/getFootDataPoints');
 }
}
