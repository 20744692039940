import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import {ForgetpasswordComponent} from './forgetpassword/forgetpassword.component'
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
const routes: Routes =[
  {
    path: '',
    redirectTo: 'steps-report',
    pathMatch: 'full',
  },
  { path: 'login', component: LoginComponent },
  { path: 'register',     component: RegisterComponent },
  { path: 'forgetpassword',     component: ForgetpasswordComponent }, 
  {path: 'app/VerifyAccount/:param1/:param2',     component:VerifyAccountComponent},
  {path:  'app/ChangePassword/:param3/mobile',   component:ChangePasswordComponent },
  {path:  'app/ChangePassword/:param3/web',   component:ChangePasswordComponent },

  {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  }
];
 
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
